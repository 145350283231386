<template>
    <div class="root-wrap">
        <div class="fx-fill fx-center-middle">
            {{errorCode}}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorCode: this.$route.params.errorCode,
        }
    }
}
</script>

<style>

</style>